.toggle {
    height: 55px;
    width: 155px;
    border-radius: 50px;
    margin: auto;
    background-image: linear-gradient(aqua, skyblue);
    position: relative;
    cursor: pointer;
}

.toggle.night {
    background-image: linear-gradient(midnightblue, rebeccapurple);
}

.notch {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background: yellow;
    position: absolute;
    top: 5px;
    left: 5px;
    box-shadow: 0 0 5px yellow;
    z-index: 1;
    transition: all 0.3s ease;
}

.notch > .crater {
    background: burlywood;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4) inset;
}
.night .crater {
    opacity: 0.4;
}

.crater:first-child {
    left: 5px;
    top: 15px;
    height: 15px;
    width: 40px;
    transform: rotate(-45deg);
}

.crater:last-child {
    right: 10px;
    top: 15px;
    height: 25px;
    width: 25px;
    transform: rotate(45deg);
}

.night > .notch {
    background: whitesmoke;
    box-shadow: 0 0 5px whitesmoke;
    transform: translate(100px, 0);
}

.shape {
    position: absolute;
    background: whitesmoke;
    border-radius: 50%;
    transition: all 0.3s ease;
}

.shape.sm {
    height: 12px;
    width: 30px;
    top: 50%;
    left: 60%;
}

.shape.md {
    height: 10px;
    width: 25px;
    top: 25%;
    left: 25%;
    z-index: 2;
}

.shape.lg {
    height: 15px;
    width: 50px;
    bottom: 20px;
    left: 25%;
}

.night .shape {
    background: lightgray;
    box-shadow: 0 0 10px 2px violet;
}

.night .shape.sm {
    height: 5px;
    width: 5px;
    transform: translate(-40px, 0);
}

.night .shape.sm:first-of-type {
    transform: translate(-80px, -10px);
}

.night .shape.md {
    height: 10px;
    width: 10px;
    transform: translate(10px, 0);
}

.night .shape.lg {
    height: 15px;
    width: 15px;
    transform: translate(-10px, 0);
}
